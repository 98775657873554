<template>
  <div class="blog">
    <div class="container textContainer">
      <h1 class="sectionTitle bef">
        <!-- {{ countryInfo.name }} -->
        {{ blogTitle }}
      </h1>
      <p class="descr" v-html="blogDesc">
        <!-- {{ countryInfo.description | removeTagP }} -->
      </p>
      <app-tabs :tabList="tabListM"></app-tabs>
      <section>
        <div class="newsWrapper">
          <template v-if="models">
            <app-news-item
              v-for="(item, index) in models"
              :key="index"
              :data="item.translate"
              :link="item.model.url"
            ></app-news-item>
          </template>
          <div v-else>no items</div>
        </div>
      </section>
      <app-pagination
        v-if="pagiData && pagiData.length > 3"
        :compdata="pagiData"
        @click="onPagiClick"
      ></app-pagination>
    </div>
    <div class="container-full">
      <app-subscribe-form></app-subscribe-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppNewsItem from "../components/common/app-news-item.vue";
import AppPagination from "../components/common/app-pagination.vue";
import AppSubscribeForm from "../components/common/app-subscribe-form.vue";
import appTabs from "../components/common/app-tabs.vue";

export default {
  name: "blogPage",
  components: {
    appTabs,
    AppNewsItem,
    AppPagination,
    AppSubscribeForm
  },
  data() {
    return {
      models: null,
      tabList: null,
      currentPage: 1,
      totalPage: 0
    };
  },
  watch: {
    currentPage() {
      window.scrollTo(0, 0);
    }
  },
  computed: {
    tabListM() {
      const temp = this.tabList;
      if (temp) {
        temp.unshift({
          name: this.$t("All_news"),
          type: "self"
        });
      }
      return temp;
    },
    ...mapGetters(["blogTitle", "blogDesc"]),
    pagiData() {
      if (this.currentPage) {
        let p = [];
        if (this.currentPage > 1) p.push({ type: "prev", s: true });
        else p.push({ type: "prev", s: false });
        let pre = this.currentPage;
        if (pre > 4) {
          p.push({ type: "page", l: 1 });
          p.push({ type: "dot" });
          for (let i = this.currentPage - 2; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        } else {
          for (let i = 1; i < this.currentPage; i++) {
            p.push({ type: "page", l: i, current: false });
          }
        }
        let post = this.totalPage - this.currentPage;
        if (post > 4) {
          for (let i = this.currentPage; i < this.currentPage + 3; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
          p.push({ type: "dot" });
          p.push({ type: "page", l: this.totalPage });
        } else {
          for (let i = this.currentPage; i <= this.totalPage; i++) {
            if (i == this.currentPage)
              p.push({ type: "page", l: i, current: true });
            else p.push({ type: "page", l: i, current: false });
          }
        }
        if (this.currentPage < this.totalPage)
          p.push({ type: "next", s: true });
        else p.push({ type: "next", s: false });
        return p;
      }
      return null;
    }
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"]),
    onPagiClick(p) {
      switch (p) {
        case "prev":
          this.currentPage--;
          this.sendQuery(this.currentPage - 1);
          break;
        case "next":
          this.currentPage++;
          this.sendQuery(this.currentPage + 1);
          break;
        default:
          this.currentPage = p;
          this.sendQuery(p);
          break;
      }
    },
    async sendQuery(page) {
      this.lockUi();
      let res;
      if (this.$route.name == "Blog") {
        res = await this.axios.post("/api/blog/articles/all", {
          lang: this.currentLang,
          page: this.currentPage
        });
      } else if (this.$route.name == "BlogCategory") {
        res = await this.axios.post("/api/blog/articles/get-by-category-slug", {
          lang: this.currentLang,
          slug: this.$route.params.slug,
          page: this.currentPage
        });
      }

      let pushObj = { ...this.$route };
      pushObj.query.page = page;
      const resolved = this.$router.resolve(pushObj);
      window.history.pushState(null, null, resolved.href);

      this.models = res.data.data.items.models;
      this.tabList = res.data.data.categories_list;
      for (const key in this.tabList) {
        if (Object.hasOwnProperty.call(this.tabList, key)) {
          this.tabList[key].type = "newsCategory";
        }
      }

      this.currentPage = res.data.data.items.paginate.current_page;
      this.totalPage = Math.ceil(
        res.data.data.items.paginate.total /
          res.data.data.items.paginate.per_page
      );
      //   res.data.data.translate.meta_title || res.data.data.translate.name ||
      document.title = "Dream Travel";
      this.unlockUi();
    }
  },
  created() {
    this.sendQuery();
  }
};
</script>

<style lang="sass" scoped>
.newsWrapper
    // margin-top: 30px
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 30px
    text-align: left
    @media (max-width: 1024px)
        grid-template-columns: repeat(2, 1fr)
    @media (max-width: 576px)
        grid-template-columns: 1fr
</style>
