<template>
  <div>
    <app-link :link="propsData.link" class="btn">{{
      propsData.title
    }}</app-link>
  </div>
</template>

<script>
import appLink from "../navigation/app-link.vue";
export default {
  components: { appLink },
  name: "AppLinkButton",
  props: {
    propsData: {
      type: [Array, Object]
    }
  }
};
</script>

<style lang="scss" scoped></style>
