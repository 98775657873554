<template>
  <div :class="{ textPage: $route.params.textPageSlug !== 'contacts' }">
    <section class="container mb article" v-if="!isUiLocked" ref="contentZone">
      <!-- <div class="textContainer">
        <the-main-text
          :title="textPageData.translate.name"
          :descr="textPageData.translate.text"
        ></the-main-text>
      </div> -->
      <!-- для блога -->
      <!-- <div class="uiElements">
        <ul>
          <li>
            <router-link
              :to="{
                name: 'Blog',
                params: {
                  locale: currentLangForLink
                }
              }"
              class="back"
              >Назад</router-link
            >
          </li>
          <li class="isIco">
            <a href="#"><i class="ic-fsi"></i></a>
          </li>
          <li class="isIco">
            <a href="#"><i class="ic-twi"></i></a>
          </li>
          <li class="isIco">
            <a href="#"><i class="ic-shareLink"></i></a>
          </li>
        </ul>
        <span class="date">{{ textPageData.model.public_date }}</span>
      </div> -->
      <share-bar :date="textPageData.model.public_date" />
      <h1>{{ textPageData.translate.name }}</h1>
      <img
        class="mainImg"
        :src="path(textPageData.translate.image)"
        :alt="path(textPageData.translate.image)"
      />
      <component
        v-for="(item, idx) in constructorList"
        :is="item.component"
        :key="idx"
        :propsData="item.content"
      >
      </component>
      <component
        v-for="(wItem, idx) in widgetList"
        :is="wItem.component"
        :key="`${idx}w`"
        :propsData="wItem.content"
      >
      </component>
    </section>
    <app-article-tags v-if="tags || tags.length" :propsData="tags" ></app-article-tags>
    <app-news :propsData="similarArticles" ></app-news>
    <div class="container-full">
        <app-subscribe-form></app-subscribe-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppNews from "../components/app-news.vue";
import TheMainText from "../components/common/the-main-text.vue";
// import TheMap from "../components/contacts/the-map.vue";
import appSimpleText from "../components/text-components/app-simple-text.vue";
import AppTextAndImage from "../components/text-components/app-text-and-image.vue";
import accordionMixin from "@/textAccordion";
import AppQuote from "../components/text-components/app-quote.vue";
import ShareBar from "../components/ShareBar.vue";
import AppLinkButton from "../components/text-components/app-link-button.vue";
import AppSubscribeForm from '../components/common/app-subscribe-form.vue';
import AppArticleTags from '../components/article/app-article-tags.vue';
export default {
  name: "ArticlePage",
  components: {
    "simple-text": appSimpleText,
    "image-and-text": AppTextAndImage,
    AppNews,
    TheMainText,
    // TheMap,
    quotes: AppQuote,
    ShareBar,
    "link-button": AppLinkButton,
    AppSubscribeForm,
    AppArticleTags
  },
  mixins: [accordionMixin],
  data() {
    return {
      textPageData: null,
      constructorList: null,
      widgetList: null,
      similarArticles: null,
      tags: []
    };
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"])
  },
  computed: {
    ...mapGetters(["isUiLocked"]),
    currentLocation() {
      return window.location.href;
    }
  },
  async created() {
    this.lockUi();
    const res = await this.axios.post("/api/blog/articles/get-by-slug", {
      lang: this.currentLang,
      slug: this.$route.params.slug
    });
    this.textPageData = res.data.data;
    this.constructorList = res.data.data.constructor;
    this.widgetList = res.data.data.widgets;
    this.similarArticles = res.data.data.similar_articles
    this.tags = res.data.data.tags
    document.title = res.data.data.translate.meta_title || res.data.data.translate.name || "Dream Travel"
    this.unlockUi();
  },
  mounted() {
    setTimeout(() => {
      this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
    }, 500);
  }
};
</script>

<style lang="sass" scoped>
.mb
    // margin-bottom: $section-offset
    @media (max-width: 830px)
        margin-bottom: 100px
    @media (max-width: 576px)
        margin-bottom: 0px
.mainImg
    margin-bottom: 37px
</style>
